.minimiser {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  width: 42px;
  min-width: 42px;
  height: 42px;
  min-height: 42px;
  z-index: 1;
  background: white;
  box-sizing: border-box;

  .box {
    div,
    svg {
      width: 14px;
      height: 14px;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      place-content: center;
      box-sizing: border-box;
    }
  }

  &.small {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    .box {
      div,
      svg {
        width: 12px;
        height: 12px;
        font-size: 10px;
      }
    }
  }

  &.large {
    width: 66px;
    min-width: 66px;
    height: 66px;
    min-height: 66px;

    .box {
      div,
      svg {
        width: 22px;
        height: 22px;
        font-size: 18px;
      }
    }
  }
}

/* Tablet styles */
@media only screen and (min-width: 769px) and (max-width: 1224px) {
  .minimiser {
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;

    .box {
      div,
      svg {
        width: 16px;
        height: 16px;
        font-size: 12px;
      }
    }
  }
}

/* Computer styles */
@media only screen and (min-width: 1225px) {
  .minimiser {
    width: 54px;
    min-width: 54px;
    height: 54px;
    min-height: 54px;

    .box {
      div,
      svg {
        width: 18px;
        height: 18px;
        font-size: 14px;
      }
    }
  }
}
