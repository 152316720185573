.container {
  height: 100svh;
  max-height: 100svh;

  overflow: none;
  display: flex;
  flex-direction: column;
}

.topbar,
.bottombar {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 0;
  gap: 2px;
  width: 100%;
}

.bottombar {
  flex-wrap: wrap;
  align-content: flex-end;
  align-items: center;
}

.middle {
  flex: 1;
  display: flex;
  height: calc(
    100vh - 104px
  ); /* Subtracting the combined heights of topbar and bottombar */
}

.leftSidebar,
.rightSidebar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  gap: 2px;
}

.rightSidebar {
  flex-wrap: wrap-reverse;
}

.topbar {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.mainContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none !important;

  canvas {
    position: fixed;
    background: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
}
