.editorCanvas {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;

  &.disabled {
    pointer-events: none !important;

    canvas {
      pointer-events: none !important;
    }
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
    background: transparent;
  }
}
