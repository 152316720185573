.container {
  font-family: Arial, sans-serif;
  margin: 20px;

  .table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }

  .header {
    background-color: #f2f2f2;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  .record-button,
  .play-button {
    cursor: pointer;
  }
}
