.box {
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  width: 42px;
  height: 42px;
  min-height: 42px;
  min-width: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  place-content: center;

  .small {
    width: 32.5px;
    height: 32.5px;
    min-height: 32.5px;
    min-width: 32.5px;
    font-size: 18px;
  }

  .large {
    width: 80px;
    height: 80px;
    min-height: 80px;
    min-width: 80px;
    font-size: 28px;
  }
}

.selected {
  transform: scale(1.3);
  border: solid 2px white !important;
  background: white;
}

/* Tablet styles */
@media only screen and (min-width: 769px) and (max-width: 1224px) {
  .box {
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    font-size: 28px;
  }
}

/* Computer styles */
@media only screen and (min-width: 1225px) {
  .box {
    width: 54px;
    height: 54px;
    min-height: 54px;
    min-width: 54px;
    font-size: 28px;
  }
}
