.inputField {
  border: none;
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  width: 64px;
  height: 64px;
  outline: none;
  background: linear-gradient(45deg, rgb(5, 122, 240), rgb(9, 155, 18));
  border: solid black 2px;
  transition: 0.3s;
  box-sizing: border-box;

  &:active {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(3px);
  }
}
