.storybook {
  display: flex;
  flex-direction: row;
  pointer-events: auto !important;
  width: 300px;
  align-items: center;
  gap: 16px;

  button {
    min-width: unset;
  }
}
