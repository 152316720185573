.wiggleModal {
  font-family: "Comic Sans MS", cursive, sans-serif;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffcc00;
  border: 5px dashed #66a3ff;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  font-family: "Comic Sans MS", cursive, sans-serif;
  box-shadow: 0 4px 12px grey;
  width: 200px;
  z-index: 1000;
}

.wiggleModal h1 {
  color: #1cb123;
  font-size: 30px;
  margin: 12px;
}

.wiggleModal p {
  color: #66a3ff;
  font-size: 18px;
}

.wiggleModal button {
  background-color: #66a3ff;
  width: 100%;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
}
